<template>
  <div class="flex justify-content-center mb-2">
      <SelectButton v-model="modifiedTimeMode.value" :options="this.$store.getters.getTimeType" optionLabel="name" optionValue="value" dataKey="value" aria-labelledby="name" @update:model-value="modifyTimeMode">
          <template #option="slotProps">
              <span class="flex justify-content-center mx-auto" v-tooltip="slotProps.option.name"><i :class="slotProps.option.icon" class="text-xl icon-inline"></i></span>
          </template>
      </SelectButton>
  </div>
  <div class="field">
      <div class="p-inputgroup">
          <span v-if="mode !== 0" class="p-inputgroup-addon">
              <ToggleButton v-model="modifiedTimeObj.plusminus" onIcon="fi fi-rr-minus" offIcon="fi fi-rr-plus" />
          </span>
          <span class="p-float-label mr-2">
              <InputMask id="minTimeStart" ref="minValueTimeStart" mask="99:99:99"
                  placeholder="" slotChar="hh:mm:ss"
                  v-model="modifiedTimeObj.value"
                  @update:model-value="modifiedTimeObj"
                  class="p-inputtext-lg" />
              <label for="minValueTimeStart" class="mr-2">{{ titleStr }}</label>
          </span>
      </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import { formatDateGui } from "@/helpers";

export default defineComponent({
  name: "widgetTimeSet",
  setup() {},
  props: {
    title: {
      type: String,
      required: false
    },
    timeModeObj: {
      type: Object,
      required: false
    },
    timeObj: {
      type: Object,
      required: false
    },
  },
  watch: {

  },
  computed: {
    titleStr: function () {
      if (this.title) return this.title;
      return `${this.timeObj.label}`;
    },
  },
  data() {
    return {
      loading: true,
      modifiedTimeMode: this.timeModeObj,
      modifiedTimeObj: this.timeObj,
    };
  },
  mounted() {
  },
  methods: {
    modifyTimeMode() {
      this.$emit('modifyTimeMode', this.modifiedTimeMode);
    },
    modifyTimeObj() {
      this.$emit('modifyTimeObj', this.modifiedTimeObj);
    },
  },
});
</script>

<style lang="scss" scoped>

</style>